






import useStore from "@/use/useStore"
import { computed, defineComponent } from "@vue/composition-api"
import Mission from "@shared/Mission"
import { Mission as MissionType } from "@shared/enums"

export default defineComponent({
  name: "MobileMedia",
  components: {
    AssetMapper: () => import("@/components/GroupTeams/Common/AssetMapper.vue")
  },
  setup() {
    const { store } = useStore()

    const media = computed(() => store.getters.gameStatus.media)

    const mission = computed(() => store.getters?.getCurrentMission)

    const missionType = computed(() => mission.value?.behavior)

    const visible = computed(() => {
      if (
        missionType.value === MissionType.YouTube ||
        Mission.isDrawing(mission.value)
      ) {
        return false
      }

      return !!media.value
    })

    return { visible, media }
  }
})
